/*!
 * RIViR 
 * styles.css -
 * Version - 1.0
 */

 @import "~@ng-select/ng-select/themes/default.theme.css";
 @import "~@angular/material/prebuilt-themes/indigo-pink.css";
 @import "./assets/css/bootstrap.min.css";
 /* @import "./assets/css/googlefonts.css"; */
 @import "./assets/css/toastr.min.css";
 @import "./assets/material-icons/material-icons.css";

 @font-face {
   font-family: 'Source Sans Pro';
   font-style: normal;
   font-weight: 300;
   src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('assets/fonts/sourcesanspro/sourcesanspro-light.woff') format('woff');
 }
 @font-face {
   font-family: 'Source Sans Pro';
   font-style: normal;
   font-weight: 400;
   src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url('assets/fonts/sourcesanspro/sourcesanspro.woff') format('woff');
 }
 @font-face {
   font-family: 'Source Sans Pro';
   font-style: normal;
   font-weight: 700;
   src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('assets/fonts/sourcesanspro/sourcesanspro-bold.woff') format('woff');
 }

 html {
   background-color: #FAFAFA;
   scroll-behavior: smooth;

 }
 
 body {
   font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size: 14px;
   -webkit-font-smoothing: antialiased;
   line-height: 1.42857143;
   color: #58666e;
   background-color: transparent;
 }
 
 *:focus {
   outline: 0 !important;
 }
 
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6 {
   margin: 0;
 }
 
 a {
   color: inherit;
   text-decoration: none;
   cursor: pointer;
 }
 
 a:hover,
 a:focus {
   color: inherit;
   text-decoration: none;
 }
   
 label {
   font-weight: normal;
 }

 .accordion-group,
 .accordion-inner {
   border-color: #dee5e7;
   border-radius: 2px;
 }
 
 .alert {
   font-size: 13px;
   box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
 }
 
 .alert .close i {
   display: block;
   font-size: 12px;
   font-weight: normal;
 }
 
 .form-control {
   border-color: #cfdadd;
   border-radius: 2px;
 }
 
 .form-control,
 .form-control:focus {
   -webkit-box-shadow: none;
           box-shadow: none;
 }
 
 .form-control:focus {
   border-color: #23b7e5;
 }
 
 .form-control-spin {
   position: absolute;
   top: 50%;
   right: 10px;
   z-index: 2;
   margin-top: -7px;
 }
 
 .input-group-addon {
   background-color: #edf1f2;
   border-color: #cfdadd;
 }
   
 .nav > li > a:hover,
 .nav > li > a:focus {
   background-color: rgba(0, 0, 0, 0.05);
 }
 
 .nav.nav-lg > li > a {
   padding: 20px 20px;
 }
 
 .nav.nav-md > li > a {
   padding: 15px 15px;
 }
 
 .nav.nav-sm > li > a {
   padding: 6px 12px;
 }
 
 .nav.nav-xs > li > a {
   padding: 4px 10px;
 }
 
 .nav.nav-xxs > li > a {
   padding: 1px 10px;
 }
 
 .nav.nav-rounded > li > a {
   border-radius: 20px;
 }
 
 .nav .open > a,
 .nav .open > a:hover,
 .nav .open > a:focus {
   background-color: rgba(0, 0, 0, 0.05);
 }
 
 .nav-tabs {
   border-color: #dee5e7;
 }
 
 .nav-tabs > li > a {
   border-bottom-color: #dee5e7;
   border-radius: 2px 2px 0 0;
 }
 
 .nav-tabs > li:hover > a,
 .nav-tabs > li.active > a,
 .nav-tabs > li.active > a:hover {
   border-color: #dee5e7;
 }
 
 .nav-tabs > li.active > a {
   border-bottom-color: #fff !important;
 }
 
 .nav-tabs-alt .nav-tabs.nav-justified > li {
   display: table-cell;
   width: 1%;
 }
 
 .nav-tabs-alt .nav-tabs > li > a {
   background: transparent !important;
   border-color: transparent !important;
   border-bottom-color: #dee5e7 !important;
   border-radius: 0;
 }
 
 .nav-tabs-alt .nav-tabs > li.active > a {
   border-bottom-color: #23b7e5 !important;
 }
 
 .pagination > li > a {
   border-color: #dee5e7;
 }
 
 .pagination > li > a:hover,
 .pagination > li > a:focus {
   background-color: #edf1f2;
   border-color: #dee5e7;
 }
 
 .panel {
   border-radius: 2px;
 }
 
 .panel .accordion-toggle {
   display: block;
   font-size: 14px;
   cursor: pointer;
 }
   
 .panel.no-borders {
   border-width: 0;
 }
 
 .panel.no-borders .panel-heading,
 .panel.no-borders .panel-footer {
   border-width: 0;
 }
 
 .panel-heading {
   border-radius: 2px 2px 0 0;
 }
 
 .panel-default .panel-heading {
   background-color: #f6f8f8;
 }
 
 .panel-heading.no-border {
   margin: -1px -1px 0 -1px;
   border: none;
 }
 
 .panel-heading .nav {
   margin: -10px -15px;
 }
   
 .panel-footer {
   background-color: #ffffff;
   border-color: #edf1f2;
   border-radius: 0 0 2px 2px;
 }
 
 .panel-default {
   border-color: #dee5e7;
 }
 
 .panel-default > .panel-heading,
 .panel-default > .panel-footer {
   border-color: #edf1f2;
 }
 
 .panel-group .panel-heading + .panel-collapse .panel-body {
   border-top: 1px solid #eaedef;
 }
 
 .table > tbody > tr > th,
 .table > tfoot > tr > th,
 .table > tbody > tr > td,
 .table > tfoot > tr > td {
   padding: 8px 15px;
   border-top: 1px solid #eaeff0;
 }
 
 .table > thead > tr > th {
   padding: 8px 15px;
   border-bottom: 1px solid #eaeff0;
 }
 
 .table-bordered {
   border-color: #eaeff0;
 }
 
 .table-bordered > tbody > tr > td {
   border-color: #eaeff0;
 }
 
 .table-bordered > thead > tr > th {
   border-color: #eaeff0;
 }

 .table-striped > tbody > tr:nth-child(odd) > td,
 .table-striped > tbody > tr:nth-child(odd) > th {
   background-color: #fafbfc;
 }
 
 .table-striped > thead > th {
   background-color: #fafbfc;
   border-right: 1px solid #eaeff0;
 }
 
 .table-striped > thead > th:last-child {
   border-right: none;
 }
 
 .well,
 pre {
   background-color: #edf1f2;
   border-color: #dee5e7;
 }
   
 .dropdown-header {
   padding: 5px 15px;
 }
 
 .dropdown-submenu {
   position: relative;
 }
 
 .dropdown-submenu:hover > a,
 .dropdown-submenu:focus > a {
   color: #58666e;
   background-color: #edf1f2 !important;
 }
   
 .dropdown-submenu.pull-left {
   float: none !important;
 }
   
 .btn-group > .btn {
   margin-left: -1px;
 }
 
 /*cols*/
 
 .col-lg-2-4 {
   position: relative;
   min-height: 1px;
   padding-right: 15px;
   padding-left: 15px;
 }
 
 .col-0 {
   clear: left;
 }
 
 .row.no-gutter {
   margin-right: 0;
   margin-left: 0;
 }
 
 .no-gutter [class*="col"] {
   padding: 0;
 }
 
 .row-sm {
   margin-right: -10px;
   margin-left: -10px;
 }
 
 .row-sm > div {
   padding-right: 10px;
   padding-left: 10px;
 }
 
 .modal-backdrop {
   background-color: #3a3f51;
 }
 
 .modal-backdrop.in {
   opacity: 0.8;
   filter: alpha(opacity=80);
 }
 
 .modal-over {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
 }
 
 .modal-center {
   position: absolute;
   top: 50%;
   left: 50%;
 }
 
 /*layout*/
 
 html,
 body {
   width: 100%;
   height: 100%;
 }
 
 body {
   overflow-x: hidden;
 }
 
 .hbox {
   display: table;
   width: 100%;
   height: 100%;
   border-spacing: 0;
   table-layout: fixed;
 }
 
 .hbox .col {
   display: table-cell;
   float: none;
   height: 100%;
   vertical-align: top;
 }
 
 .v-middle {
   vertical-align: middle !important;
 }
 
 .v-top {
   vertical-align: top !important;
 }
 
 .v-bottom {
   vertical-align: bottom !important;
 }
 
 .vbox {
   position: relative;
   display: table;
   width: 100%;
   height: 100%;
   min-height: 240px;
   border-spacing: 0;
 }
 
 .vbox .row-row {
   display: table-row;
   height: 100%;
 }
 
 .vbox .row-row .cell {
   position: relative;
   width: 100%;
   height: 100%;
 }
 
 .ie .vbox .row-row .cell {
   display: table-cell;
   overflow: auto;
 }
 
 .ie .vbox .row-row .cell .cell-inner {
   overflow: visible !important;
 }
 
 .vbox .row-row .cell .cell-inner {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   overflow: auto;
   -webkit-overflow-scrolling: touch;
 }
 
 .navbar {
   margin: 0;
   border-width: 0;
   border-radius: 0;
 }
 
 .navbar .navbar-form-md {
   margin-top: 20px;
   margin-bottom: 20px;
 }
 
 .navbar-md {
   min-height: 60px;
 }
 
 .navbar-md .navbar-btn {
   margin-top: 13px;
 }
 
 .navbar-md .navbar-form {
   margin-top: 15px;
 }
 
 .navbar-md .navbar-nav > li > a {
   padding-top: 20px;
   padding-bottom: 20px;
 }
 
 .navbar-md .navbar-brand {
   line-height: 60px;
 }
 
 .navbar-header > button {
   padding: 10px 17px;
   font-size: 16px;
   line-height: 30px;
   text-decoration: none;
   background-color: transparent;
   border: none;
 }
 
 .navbar-brand {
   display: inline-block;
   float: none !important;
   height: auto;
   padding: 0 18px;
   font-size: 28px;
   font-weight: 700;
   line-height: 50px;
   text-align: center;
 }
 
 .navbar-brand:hover {
   text-decoration: none;
 }
 
 .navbar-brand img {
   display: inline;
   max-height: 26px; /*customized*/
   margin-top: -4px;
   vertical-align: middle;
 }


 .navbar-header-right::before,
 .navbar-header-right::after{
   display: table;
   content: " ";
 }
 .navbar-header-right::after{
   clear: both;
 }
 .navbar-header-right {
   display: block;
   height: auto !important;
   padding-bottom: 0;
   overflow: visible !important;
   width: auto;
   border-top: 0;
   padding-right: 15px;
   padding-left: 15px;
 }
 
 .navbar-collapse.collapse.in{
   display: block !important;
 }
 .navbar-collapse.collapse{
   display: none !important;
 }


 @media (min-width: 992px) {
   .navbar-header { 
     width: 8.5%;
      max-width: 170px;
      min-width: 118px;
      margin-right:0.5%;
   }
   .navbar-header{
     height: 74px; 
   }
 }
 @media (max-width: 991px) {
   .navbar-header { 
     float: inherit
   }
 }

 @media (min-width: 768px) {

   .navbar-header{
      padding-top: 14px; /*customized */

   }
   .navbar .userFullname{  /*customized */
     font-size:22px
   }
   .navbar .userRoleType{  /*customized */
     font-size:16px;
     padding-right: 18px;
   }
   .navbar .caret{  /*customized */
     margin-left: 3px;
     border-top: 8px dashed;
     border-right: 6px solid transparent;
     border-left: 6px solid transparent;
     margin-top: -2px;
     opacity: 0.9;
   }
   .navbar-nav > li > a.alert-icon{
     padding-top: 25px;
     padding-bottom: 24px;
     font-size: 22px;
   }
   .visible-folded {
     display: none;
   }
   .col.show {
     display: table-cell !important;
   }
 }
 
 @media (min-width: 768px) and (max-width: 991px) {
   .hbox-auto-sm {
     display: block;
   }
   .hbox-auto-sm > .col {
     display: block;
     width: auto;
     height: auto;
   }
   .hbox-auto-sm > .col.show {
     display: block !important;
   }
   .hbox-auto-sm .vbox {
     height: auto;
   }
   .hbox-auto-sm .cell-inner {
     position: static !important;
   }
 }
 
 @media (max-width: 767px) {
   body {
     height: auto;
     min-height: 100%;
   }
   .navbar-fixed-bottom {
     position: fixed;
   }
   .hbox-auto-xs {
     display: block;
   }
   .hbox-auto-xs > .col {
     display: block;
     width: auto;
     height: auto;
   }
   .hbox-auto-xs .vbox {
     height: auto;
   }
   .hbox-auto-xs .cell-inner {
     position: static !important;
   }
   .navbar-nav {
     margin-top: 0;
     margin-bottom: 0;
   }
   .navbar-nav > li > a {
     box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
   }
   .navbar-nav > li > a .up {
     top: 0;
   }
   .navbar-nav > li > a .avatar {
     width: 30px;
     margin-top: -5px;
   }
   .navbar-form {
     margin-top: 0 !important;
     margin-bottom: 0 !important;
     box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
   }
   .navbar-form .form-group {
     margin-bottom: 0;
   }
 }

 .app.container {
   padding-right: 0;
   padding-left: 0;
 }  
 
 .nav-sub {
   height: 0;
   margin-left: -20px;
   overflow: hidden;
   opacity: 0;
   -webkit-transition: all 0.2s ease-in-out 0s;
           transition: all 0.2s ease-in-out 0s;
 }
 
 .active > .nav-sub {
   height: auto !important;
   margin-left: 0;
   overflow: auto;
   opacity: 1;
 }
 
 .nav-sub-header {
   display: none !important;
 }
 
 .nav-sub-header a {
   padding: 15px 20px;
 }
 
 .navi ul.nav li {
   position: relative;
   display: block;
 }
 
 .navi ul.nav li li a {
   padding-left: 55px;
 }
 
 .navi ul.nav li li ul {
   display: none;
 }
 
 .navi ul.nav li li.active > ul {
   display: block;
 }
 
 .navi ul.nav li a {
   position: relative;
   display: block;
   padding: 10px 20px;
   font-weight: normal;
   text-transform: none;
   -webkit-transition: background-color 0.2s ease-in-out 0s;
           transition: background-color 0.2s ease-in-out 0s;
 }
 
 .navi ul.nav li a .badge,
 .navi ul.nav li a .label {
   padding: 2px 5px;
   margin-top: 2px;
   font-size: 11px;
 }
 
 .navi ul.nav li a > i {
   position: relative;
   float: left;
   width: 40px;
   margin: -10px -10px;
   margin-right: 5px;
   overflow: hidden;
   line-height: 40px;
   text-align: center;
 }
 
 .navi ul.nav li a > i:before {
   position: relative;
   z-index: 2;
 }  
 
 @media (max-width: 767px) {
   html,
   body {
     overflow-x: hidden !important;
   }
   .off-screen {
     position: fixed;
     top: 50px;
     bottom: 0;
     z-index: 1010;
     display: block !important;
     width: 75%;
     overflow-x: hidden;
     overflow-y: auto;
     visibility: visible;
     -webkit-overflow-scrolling: touch;
   }
   .off-screen + * {
     position: fixed;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 1015;
     width: 100%;
     padding-top: 50px;
     overflow: hidden;
     background-color: #f0f3f4;
     -webkit-transform: translate3d(75%, 0, 0px);
             transform: translate3d(75%, 0, 0px);
     -webkit-transition: -webkit-transform 0.2s ease;
        -moz-transition: -moz-transform 0.2s ease;
          -o-transition: -o-transform 0.2s ease;
             transition: transform 0.2s ease;
     -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
             backface-visibility: hidden;
   }
   .off-screen + * .off-screen-toggle {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 1020;
     display: block !important;
   }
   .off-screen.pull-right {
     right: 0;
   }
   .off-screen.pull-right + * {
     -webkit-transform: translate3d(-75%, 0, 0px);
             transform: translate3d(-75%, 0, 0px);
   }
 }
 
 .btn {
   font-weight: 500;
   border-radius: 2px;
   outline: 0!important;
 }
 
 .btn-link {
   color: #58666e;
 }
 
 .btn-link.active {
   box-shadow: none;
   -webkit-box-shadow: none;
 }
 
 .btn-default {
   color: #58666e !important;
   background-color: #fcfdfd;
   background-color: #fff;
   border-color: #dee5e7;
   border-bottom-color: #d8e1e3;
   -webkit-box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
           box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
 }
 
 .btn-default:hover,
 .btn-default:focus,
 .btn-default:active,
 .btn-default.active,
 .open .dropdown-toggle.btn-default {
   color: #58666e !important;
   background-color: #edf1f2;
   border-color: #c7d3d6;
 }
 
 .btn-default:active,
 .btn-default.active,
 .open .dropdown-toggle.btn-default {
   background-image: none;
 }
 
 .btn-default.disabled,
 .btn-default[disabled],
 fieldset[disabled] .btn-default,
 .btn-default.disabled:hover,
 .btn-default[disabled]:hover,
 fieldset[disabled] .btn-default:hover,
 .btn-default.disabled:focus,
 .btn-default[disabled]:focus,
 fieldset[disabled] .btn-default:focus,
 .btn-default.disabled:active,
 .btn-default[disabled]:active,
 fieldset[disabled] .btn-default:active,
 .btn-default.disabled.active,
 .btn-default[disabled].active,
 fieldset[disabled] .btn-default.active {
   background-color: #fcfdfd;
   border-color: #dee5e7;
 }
 
 .btn-default.btn-bg {
   border-color: rgba(0, 0, 0, 0.1);
   background-clip: padding-box;
 }
 
 .btn-primary {
   color: #ffffff;
   background-color: #337ab7;
   border-color: #337ab7;
 }
 
 .btn-primary:hover,
 .btn-primary:focus,
 .btn-primary:active,
 .btn-primary.active,
 .open .dropdown-toggle.btn-primary {
   color: #ffffff !important;
   background-color: #286090;
   border-color: #204d74;
 }
 
 .btn-primary:active,
 .btn-primary.active,
 .open .dropdown-toggle.btn-primary {
   background-image: none;
 }
 
 .btn-primary.disabled,
 .btn-primary[disabled],
 fieldset[disabled] .btn-primary,
 .btn-primary.disabled:hover,
 .btn-primary[disabled]:hover,
 fieldset[disabled] .btn-primary:hover,
 .btn-primary.disabled:focus,
 .btn-primary[disabled]:focus,
 fieldset[disabled] .btn-primary:focus,
 .btn-primary.disabled:active,
 .btn-primary[disabled]:active,
 fieldset[disabled] .btn-primary:active,
 .btn-primary.disabled.active,
 .btn-primary[disabled].active,
 fieldset[disabled] .btn-primary.active {
   background-color: #337ab7;
   border-color: #337ab7;
 }

 .btn-outline-success {
   color: #28a745;
   border-color: #28a745;
 }
 
 .btn-outline-success:hover {
   color: #fff;
   background-color: #28a745;
   border-color: #28a745;
 }
 
 .btn-outline-success:focus, .btn-outline-success.focus {
   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
 }
 
 .btn-outline-success.disabled, .btn-outline-success:disabled {
   color: #28a745;
   background-color: transparent;
 }
 
 .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
 .show > .btn-outline-success.dropdown-toggle {
   color: #fff;
   background-color: #28a745;
   border-color: #28a745;
 }
 
 .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
 .show > .btn-outline-success.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
 }

 .btn-outline-secondary {
   color: #6c757d;
   border-color: #6c757d;
 }
 
 .btn-outline-secondary:hover {
   color: #fff;
   background-color: #6c757d;
   border-color: #6c757d;
 }
 
 .btn-outline-secondary:focus, .btn-outline-secondary.focus {
   box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
 }
 
 .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
   color: #6c757d;
   background-color: transparent;
 }
 
 .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
 .show > .btn-outline-secondary.dropdown-toggle {
   color: #fff;
   background-color: #6c757d;
   border-color: #6c757d;
 }
 
 .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
 .show > .btn-outline-secondary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
 }  
 
 .btn-success {
   color: #ffffff !important;
   background-color: #27c24c;
   border-color: #27c24c;
 }
 
 .btn-success:hover,
 .btn-success:focus,
 .btn-success:active,
 .btn-success.active,
 .open .dropdown-toggle.btn-success {
   color: #ffffff !important;
   background-color: #23ad44;
   border-color: #20a03f;
 }
 
 .btn-success:active,
 .btn-success.active,
 .open .dropdown-toggle.btn-success {
   background-image: none;
 }
 
 .btn-success.disabled,
 .btn-success[disabled],
 fieldset[disabled] .btn-success,
 .btn-success.disabled:hover,
 .btn-success[disabled]:hover,
 fieldset[disabled] .btn-success:hover,
 .btn-success.disabled:focus,
 .btn-success[disabled]:focus,
 fieldset[disabled] .btn-success:focus,
 .btn-success.disabled:active,
 .btn-success[disabled]:active,
 fieldset[disabled] .btn-success:active,
 .btn-success.disabled.active,
 .btn-success[disabled].active,
 fieldset[disabled] .btn-success.active {
   background-color: #27c24c;
   border-color: #27c24c;
 }

 .btn > i.pull-left,
 .btn > i.pull-right {
   line-height: 1.42857143;
 }
 
 .btn-block {
   padding-right: 12px;
   padding-left: 12px;
 }
 
 .btn-addon i {
   position: relative;
   float: left;
   width: 34px;
   height: 34px;
   margin: -7px -12px;
   margin-right: 12px;
   line-height: 34px;
   text-align: center;
   background-color: rgba(0, 0, 0, 0.1);
   border-radius: 2px 0 0 2px;
 }
 
 .btn-addon i.pull-right {
   margin-right: -12px;
   margin-left: 12px;
   border-radius: 0 2px 2px 0;
 }
 
 .btn-addon.btn-sm i {
   width: 30px;
   height: 30px;
   margin: -6px -10px;
   margin-right: 10px;
   line-height: 30px;
 }
 
 .btn-addon.btn-sm i.pull-right {
   margin-right: -10px;
   margin-left: 10px;
 }
 
 .btn-addon.btn-lg i {
   width: 45px;
   height: 45px;
   margin: -11px -16px;
   margin-right: 16px;
   line-height: 45px;
 }
 
 .btn-addon.btn-lg i.pull-right {
   margin-right: -16px;
   margin-left: 16px;
 }
 
 .btn-addon.btn-default i {
   background-color: transparent;
   border-right: 1px solid #dee5e7;
 }
 
 .btn-groups .btn {
   margin-bottom: 5px;
 }
 
 .list-icon i {
   display: inline-block;
   width: 40px;
   margin: 0;
   text-align: center;
   vertical-align: middle;
   -webkit-transition: font-size 0.2s;
           transition: font-size 0.2s;
 }
 
 .list-icon div {
   line-height: 40px;
   white-space: nowrap;
 }
 
 .list-icon div:hover i {
   font-size: 26px;
 }
 
 .settings {
   position: fixed;
   top: 120px;
   right: -240px;
   z-index: 1050;
   width: 240px;
   -webkit-transition: all 0.2s;
           transition: all 0.2s;
 }
 
 .settings.active {
   right: -1px;
 }
 
 .settings > .btn {
   position: absolute;
   top: -1px;
   left: -42px;
   padding: 10px 15px;
   background: #f6f8f8 !important;
   border-color: #dee5e7;
   border-right-width: 0;
 }
 
 .settings .i-checks span b {
   float: left;
   width: 50%;
   height: 20px;
 }
 
 .settings .i-checks span b.header {
   height: 10px;
 }
 
 .visible-left {
   display: none;
 }
 
 
 .i-checks {
   padding-left: 20px;
   cursor: pointer;
 }
 
 .i-checks input {
   position: absolute;
   margin-left: -20px;
   opacity: 0;
 }
 
 .i-checks input:checked + i {
   border-color: #23b7e5;
 }
 
 .i-checks input:checked + i:before {
   top: 4px;
   left: 4px;
   width: 10px;
   height: 10px;
   background-color: #23b7e5;
 }
 
 .i-checks input:checked + span .active {
   display: inherit;
 }
 
 .i-checks input[type="radio"] + i,
 .i-checks input[type="radio"] + i:before {
   border-radius: 50%;
 }
 
 .i-checks input[disabled] + i,
 fieldset[disabled] .i-checks input + i {
   border-color: #dee5e7;
 }
 
 .i-checks input[disabled] + i:before,
 fieldset[disabled] .i-checks input + i:before {
   background-color: #dee5e7;
 }
 
 .i-checks > i {
   position: relative;
   display: inline-block;
   width: 20px;
   height: 20px;
   margin-top: -2px;
   margin-right: 4px;
   margin-left: -20px;
   line-height: 1;
   vertical-align: middle;
   background-color: #fff;
   border: 1px solid #cfdadd;
 }
 
 .i-checks > i:before {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 0;
   height: 0;
   background-color: transparent;
   content: "";
   -webkit-transition: all 0.2s;
           transition: all 0.2s;
 }
 
 .i-checks > span {
   margin-left: -20px;
 }
 
 .i-checks > span .active {
   display: none;
 }
 
 .i-checks-sm input:checked + i:before {
   top: 3px;
   left: 3px;
   width: 8px;
   height: 8px;
 }
 
 .i-checks-sm > i {
   width: 16px;
   height: 16px;
   margin-right: 6px;
   margin-left: -18px;
 }
 
 .i-checks-lg input:checked + i:before {
   top: 8px;
   left: 8px;
   width: 12px;
   height: 12px;
 }
 
 .i-checks-lg > i {
   width: 30px;
   height: 30px;
 }
 
 .datepicker {
   margin: 0 5px;
 }
 
 .datepicker .btn-default {
   border-width: 0;
   box-shadow: none;
 }
 
 .datepicker .btn[disabled] {
   opacity: 0.4;
 }
   
 /*Charts*/
 
 .jqstooltip {
   max-height: 12px;
   padding: 5px 10px !important;
   background-color: rgba(0, 0, 0, 0.8) !important;
   border: solid 1px #000 !important;
   -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
           border-radius: 3px;
   -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
           box-sizing: content-box;
 }
 
 .easyPieChart {
   position: relative;
   text-align: center;
 }
 
 .easyPieChart > div {
   position: relative;
   z-index: 1;
 }
 
 .easyPieChart > div .text {
   position: absolute;
   top: 60%;
   width: 100%;
   line-height: 1;
 }
 
 .easyPieChart > div img {
   margin-top: -4px;
 }
 
 .easyPieChart canvas {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 0;
 }
 
 #flotTip,
 .flotTip {
   z-index: 100;
   padding: 4px 10px;
   font-size: 12px;
   color: #fff;
   background-color: rgba(0, 0, 0, 0.8);
   border: solid 1px #000 !important;
   -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
           border-radius: 3px;
 }
 
 .legendColorBox > div {
   margin: 5px;
   border: none !important;
 }
 
 .legendColorBox > div > div {
   border-radius: 10px;
 }
 
 .sortable-placeholder {
   min-height: 50px;
   margin-bottom: 5px;
   list-style: none;
   border: 1px dashed #CCC;
 }
 
 .panel .dataTables_wrapper {
   padding-top: 10px;
 }
 
 .panel .dataTables_wrapper > .row {
   margin: 0;
 }
 
 .panel .dataTables_wrapper > .row > .col-sm-12 {
   padding: 0;
 }
 
 .st-sort-ascent:before {
   content: '\25B2';
 }
 
 .st-sort-descent:before {
   content: '\25BC';
 }
 
 .st-selected td {
   background: #f0f9ec !important;
 }
 
 .chosen-choices,
 .chosen-single,
 .bootstrap-tagsinput {
   border-color: #cfdadd !important;
   border-radius: 2px !important;
 }
 
 .bootstrap-tagsinput {
   padding: 5px 12px !important;
 }
 
 .item {
   position: relative;
 }
 
 .item .top {
   position: absolute;
   top: 0;
   left: 0;
 }
 
 .item .bottom {
   position: absolute;
   bottom: 0;
   left: 0;
 }
 
 .item .center {
   position: absolute;
   top: 50%;
 }
 
 .form-validation .form-control.ng-dirty.ng-invalid {
   border-color: #f05050;
 }
 
 .form-validation .form-control.ng-dirty.ng-valid,
 .form-validation .form-control.ng-dirty.ng-valid:focus {
   border-color: #27c24c;
 }
 
 .form-validation .i-checks .ng-invalid.ng-dirty + i {
   border-color: #f05050;
 }
 
 [ui-view].ng-leave {
   display: none;
 }
 
 [ui-view].ng-leave.smooth {
   display: block;
 }
 
 .smooth.ng-animate {
   position: absolute;
   width: 100%;
   height: 100%;
   overflow: hidden;
 }
 
 .fade-in-right-big.ng-enter {
   -webkit-animation: fadeInRightBig 0.5s;
           animation: fadeInRightBig 0.5s;
 }
 
 .fade-in-right-big.ng-leave {
   -webkit-animation: fadeOutLeftBig 0.5s;
           animation: fadeOutLeftBig 0.5s;
 }
 
 .fade-in-left-big.ng-enter {
   -webkit-animation: fadeInLeftBig 0.5s;
           animation: fadeInLeftBig 0.5s;
 }
 
 .fade-in-left-big.ng-leave {
   -webkit-animation: fadeOutRightBig 0.5s;
           animation: fadeOutRightBig 0.5s;
 }
 
 .fade-in-up-big.ng-enter {
   -webkit-animation: fadeInUpBig 0.5s;
           animation: fadeInUpBig 0.5s;
 }
 
 .fade-in-up-big.ng-leave {
   -webkit-animation: fadeOutUpBig 0.5s;
           animation: fadeOutUpBig 0.5s;
 }
 
 .fade-in-down-big.ng-enter {
   -webkit-animation: fadeInDownBig 0.5s;
           animation: fadeInDownBig 0.5s;
 }
 
 .fade-in-down-big.ng-leave {
   -webkit-animation: fadeOutDownBig 0.5s;
           animation: fadeOutDownBig 0.5s;
 }
 
 .fade-in.ng-enter {
   -webkit-animation: fadeIn 0.5s;
           animation: fadeIn 0.5s;
 }
 
 .fade-in.ng-leave {
   -webkit-animation: fadeOut 0.5s;
           animation: fadeOut 0.5s;
 }
 
 .fade-in-right.ng-enter {
   -webkit-animation: fadeInRight 0.5s;
           animation: fadeInRight 0.5s;
 }
 
 .fade-in-right.ng-leave {
   -webkit-animation: fadeOutLeft 0.5s;
           animation: fadeOutLeft 0.5s;
 }
 
 .fade-in-left.ng-enter {
   -webkit-animation: fadeInLeft 0.5s;
           animation: fadeInLeft 0.5s;
 }
 
 .fade-in-left.ng-leave {
   -webkit-animation: fadeOutRight 0.5s;
           animation: fadeOutRight 0.5s;
 }
 
 .fade-in-up.ng-enter {
   -webkit-animation: fadeInUp 0.5s;
           animation: fadeInUp 0.5s;
 }
 
 .fade-in-up.ng-leave {
   -webkit-animation: fadeOutUp 0.5s;
           animation: fadeOutUp 0.5s;
 }
 
 .fade-in-down.ng-enter {
   -webkit-animation: fadeInDown 0.5s;
           animation: fadeInDown 0.5s;
 }
 
 .fade-in-down.ng-leave {
   -webkit-animation: fadeOutDown 0.5s;
           animation: fadeOutDown 0.5s;
 }
     
 .teal {
   background-color: #135978;
 }

 .darker-form .ui-select-toggle{
   border-color: #ced9dd;
 }
 
 a.text-primary:hover {
   color: #286090;
 }
 
 .bg {
   background-color: #f0f3f4;
 }
 
 .pos-rlt {
   position: relative;
 }
 
 .pos-stc {
   position: static !important;
 }
 
 .pos-abt {
   position: absolute;
 }
 
 .pos-fix {
   position: fixed;
 }
 
 .show {
   visibility: visible;
 }
 
 .line {
   width: 100%;
   height: 2px;
   margin: 10px 0;
   overflow: hidden;
   font-size: 0;
 }
 
 .line-xs {
   margin: 0;
 }
 
 .line-lg {
   margin-top: 15px;
   margin-bottom: 15px;
 }
 
 .line-dashed {
   background-color: transparent;
   border-style: dashed !important;
   border-width: 0;
 }
 
 .no-line {
   border-width: 0;
 }
 
 .no-border,
 .no-borders {
   border-color: transparent;
   border-width: 0;
 }
 
 .no-radius {
   border-radius: 0;
 }
 
 .block {
   display: block;
 }
 
 .block.hide {
   display: none;
 }
 
 .inline {
   display: inline-block !important;
 }
 
 .pull-none {
   float: none;
 }
 
 .rounded {
   border-radius: 500px;
 }
 
 .clear {
   display: block;
   overflow: hidden;
 }
 
 .no-bg {
   color: inherit;
   background-color: transparent;
 }
 
 .no-select {
   -webkit-user-select: none;
    -khtml-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
           user-select: none;
   -webkit-touch-callout: none;
 }
 
 .l-h {
   line-height: 1.42857143;
 }
 
 .l-h-0x {
   line-height: 0;
 }
 
 .l-h-1x {
   line-height: 1.2;
 }
 
 .l-h-2x {
   line-height: 2em;
 }
 
 .l-s-1x {
   letter-spacing: 1;
 }
 
 .l-s-2x {
   letter-spacing: 2;
 }
 
 .l-s-3x {
   letter-spacing: 3;
 }
 
 .font-normal {
   font-weight: normal;
 }
 
 .font-thin {
   font-weight: 300;
 }
 
 .font-bold {
   font-weight: 700;
 }
 
 .text-3x {
   font-size: 3em;
 }
 
 .text-2x {
   font-size: 2em;
 }
 
 .text-lg {
   font-size: 18px;
 }
 
 .text-md {
   font-size: 16px;
 }
 
 .text-base {
   font-size: 14px;
 }
 
 .text-sm {
   font-size: 13px;
 }
 
 .text-xs {
   font-size: 12px;
 }
 
 .text-xxs {
   text-indent: -9999px;
 }
 
 .text-ellipsis {
   display: block;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
 }
 
 .text-u-c {
   text-transform: uppercase;
 }
 
 .text-l-t {
   text-decoration: line-through;
 }
 
 .text-u-l {
   text-decoration: underline;
 }
 
 .text-active,
 .active > .text,
 .active > .auto .text {
   display: none !important;
 }
 
 .active > .text-active,
 .active > .auto .text-active {
   display: inline-block !important;
 }
 
 .box-shadow {
   box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
 }
 
 .box-shadow-lg {
   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
 }
 
 .text-shadow {
   font-size: 170px;
   text-shadow: 0 1px 0 #dee5e7, 0 2px 0 #fcfdfd, 0 5px 10px rgba(0, 0, 0, 0.125), 0 10px 20px rgba(0, 0, 0, 0.2);
 }
 
 .no-shadow {
   -webkit-box-shadow: none !important;
           box-shadow: none !important;
 }
 
 .wrapper-xs {
   padding: 5px;
 }
 
 .wrapper-sm {
   padding: 10px;
 }
 
 .wrapper {
   padding: 15px;
 }
 
 .wrapper-md {
   padding: 20px;
 }
 
 .wrapper-lg {
   padding: 30px;
 }
 
 .wrapper-xl {
   padding: 50px;
 }
 
 .padder-lg {
   padding-right: 30px;
   padding-left: 30px;
 }
 
 .padder-md {
   padding-right: 20px;
   padding-left: 20px;
 }
 
 .padder {
   padding-right: 15px;
   padding-left: 15px;
 }
 
 .padder-v {
   padding-top: 15px;
   padding-bottom: 15px;
 }
 
 .no-padder {
   padding: 0 !important;
 }
 
 .pull-in {
   margin-right: -15px;
   margin-left: -15px;
 }
 
 .pull-out {
   margin: -10px -15px;
 }
 
 .b {
   border: 1px solid rgba(0, 0, 0, 0.05);
 }
 
 .b-a {
   border: 1px solid #dee5e7;
 }
 
 .b-t {
   border-top: 1px solid #dee5e7;
 }
 
 .b-r {
   border-right: 1px solid #dee5e7;
 }
 
 .b-b {
   border-bottom: 1px solid #dee5e7;
 }
 
 .b-l {
   border-left: 1px solid #dee5e7;
 }
 
 .b-light {
   border-color: #edf1f2;
 }
 
 .b-dark {
   border-color: #116388;
 }
 
 .b-black {
   border-color: #3a3f51;
 }
 
 .b-primary {
   border-color: #337ab7;
 }
 
 .b-success {
   border-color: #27c24c;
 }
 
 .b-info {
   border-color: #23b7e5;
 }
 
 .b-warning {
   border-color: #fad733;
 }
 
 .b-danger {
   border-color: #f05050;
 }
 
 .b-white {
   border-color: #ffffff;
 }
 
 .b-dashed {
   border-style: dashed !important;
 }
 
 .b-l-light {
   border-left-color: #edf1f2;
 }
 
 .b-l-dark {
   border-left-color: #3a3f51;
 }
 
 .b-l-black {
   border-left-color: #3a3f51;
 }
 
 .b-l-primary {
   border-left-color: #337ab7;
 }
 
 .b-l-success {
   border-left-color: #27c24c;
 }
 
 .b-l-info {
   border-left-color: #23b7e5;
 }
 
 .b-l-warning {
   border-left-color: #fad733;
 }
 
 .b-l-danger {
   border-left-color: #f05050;
 }
 
 .b-l-white {
   border-left-color: #ffffff;
 }
 
 .b-l-2x {
   border-left-width: 2px;
 }
 
 .b-l-3x {
   border-left-width: 3px;
 }
 
 .b-l-4x {
   border-left-width: 4px;
 }
 
 .b-l-5x {
   border-left-width: 5px;
 }
 
 .b-2x {
   border-width: 2px;
 }
 
 .b-3x {
   border-width: 3px;
 }
 
 .b-4x {
   border-width: 4px;
 }
 
 .b-5x {
   border-width: 5px;
 }
 
 .r {
   border-radius: 2px 2px 2px 2px;
 }
 
 .r-2x {
   border-radius: 4px;
 }
 
 .r-3x {
   border-radius: 6px;
 }
 
 .r-l {
   border-radius: 2px 0 0 2px;
 }
 
 .r-r {
   border-radius: 0 2px 2px 0;
 }
 
 .r-t {
   border-radius: 2px 2px 0 0;
 }
 
 .r-b {
   border-radius: 0 0 2px 2px;
 }
 
 .m-xxs {
   margin: 2px 4px;
 }
 
 .m-xs {
   margin: 5px;
 }
 
 .m-sm {
   margin: 10px;
 }
 
 .m {
   margin: 15px;
 }
 
 .m-md {
   margin: 20px;
 }
 
 .m-lg {
   margin: 30px;
 }
 
 .m-xl {
   margin: 50px;
 }
 
 .m-n {
   margin: 0 !important;
 }
 
 .m-l-none {
   margin-left: 0 !important;
 }
 
 .m-l-xs {
   margin-left: 5px;
 }
 
 .m-l-sm {
   margin-left: 10px;
 }
 
 .m-l {
   margin-left: 15px;
 }
 
 .m-l-md {
   margin-left: 20px;
 }
 
 .m-l-lg {
   margin-left: 30px;
 }
 
 .m-l-xl {
   margin-left: 40px;
 }
 
 .m-l-xxl {
   margin-left: 50px;
 }
 
 .m-l-n-xxs {
   margin-left: -1px;
 }
 
 .m-l-n-xs {
   margin-left: -5px;
 }
 
 .m-l-n-sm {
   margin-left: -10px;
 }
 
 .m-l-n {
   margin-left: -15px;
 }
 
 .m-l-n-md {
   margin-left: -20px;
 }
 
 .m-l-n-lg {
   margin-left: -30px;
 }
 
 .m-l-n-xl {
   margin-left: -40px;
 }
 
 .m-l-n-xxl {
   margin-left: -50px;
 }
 
 .m-t-none {
   margin-top: 0 !important;
 }
 
 .m-t-xxs {
   margin-top: 1px;
 }
 
 .m-t-xs {
   margin-top: 5px;
 }
 
 .m-t-sm {
   margin-top: 10px;
 }
 
 .m-t {
   margin-top: 15px;
 }
 
 .m-t-md {
   margin-top: 20px;
 }
 
 .m-t-lg {
   margin-top: 30px;
 }
 
 .m-t-xl {
   margin-top: 40px;
 }
 
 .m-t-xxl {
   margin-top: 50px;
 }
 
 .m-t-n-xxs {
   margin-top: -1px;
 }
 
 .m-t-n-xs {
   margin-top: -5px;
 }
 
 .m-t-n-sm {
   margin-top: -10px;
 }
 
 .m-t-n {
   margin-top: -15px;
 }
 
 .m-t-n-md {
   margin-top: -20px;
 }
 
 .m-t-n-lg {
   margin-top: -30px;
 }
 
 .m-t-n-xl {
   margin-top: -40px;
 }
 
 .m-t-n-xxl {
   margin-top: -50px;
 }
 
 .m-r-none {
   margin-right: 0 !important;
 }
 
 .m-r-xxs {
   margin-right: 1px;
 }
 
 .m-r-xs {
   margin-right: 5px;
 }
 
 .m-r-sm {
   margin-right: 10px;
 }
 
 .m-r {
   margin-right: 15px;
 }
 
 .m-r-md {
   margin-right: 20px;
 }
 
 .m-r-lg {
   margin-right: 30px;
 }
 
 .m-r-xl {
   margin-right: 40px;
 }
 
 .m-r-xxl {
   margin-right: 50px;
 }
 
 .m-r-n-xxs {
   margin-right: -1px;
 }
 
 .m-r-n-xs {
   margin-right: -5px;
 }
 
 .m-r-n-sm {
   margin-right: -10px;
 }
 
 .m-r-n {
   margin-right: -15px;
 }
 
 .m-r-n-md {
   margin-right: -20px;
 }
 
 .m-r-n-lg {
   margin-right: -30px;
 }
 
 .m-r-n-xl {
   margin-right: -40px;
 }
 
 .m-r-n-xxl {
   margin-right: -50px;
 }
 
 .m-b-none {
   margin-bottom: 0 !important;
 }
 
 .m-b-xxs {
   margin-bottom: 1px;
 }
 
 .m-b-xs {
   margin-bottom: 5px;
 }
 
 .m-b-sm {
   margin-bottom: 10px;
 }
 
 .m-b {
   margin-bottom: 15px;
 }
 
 .m-b-md {
   margin-bottom: 20px;
 }
 
 .m-b-lg {
   margin-bottom: 30px;
 }
 
 .m-b-xl {
   margin-bottom: 40px;
 }
 
 .m-b-xxl {
   margin-bottom: 50px;
 }
 
 .m-b-n-xxs {
   margin-bottom: -1px;
 }
 
 .m-b-n-xs {
   margin-bottom: -5px;
 }
 
 .m-b-n-sm {
   margin-bottom: -10px;
 }
 
 .m-b-n {
   margin-bottom: -15px;
 }
 
 .m-b-n-md {
   margin-bottom: -20px;
 }
 
 .m-b-n-lg {
   margin-bottom: -30px;
 }
 
 .m-b-n-xl {
   margin-bottom: -40px;
 }
 
 .m-b-n-xxl {
   margin-bottom: -50px;
 }
 
 .avatar {
   position: relative;
   display: block;
   white-space: nowrap;
   border-radius: 500px;
 }
 
 .avatar img {
   width: 100%;
   border-radius: 500px;
 }
 
 .avatar i {
   position: absolute;
   top: 0;
   left: 0;
   width: 10px;
   height: 10px;
   margin: 2px;
   border-style: solid;
   border-width: 2px;
   border-radius: 100%;
 }
 
 .avatar i.right {
   right: 0;
   left: auto;
 }
 
 .avatar i.bottom {
   top: auto;
   right: 0;
   bottom: 0;
   left: auto;
 }
 
 .avatar i.left {
   top: auto;
   bottom: 0;
 }
 
 .avatar i.on {
   background-color: #27c24c;
 }
 
 .avatar i.off {
   background-color: #98a6ad;
 }
 
 .avatar i.busy {
   background-color: #f05050;
 }
 
 .avatar i.away {
   background-color: #fad733;
 }
 
 .avatar.thumb-md i {
   width: 12px;
   height: 12px;
   margin: 3px;
 }
 
 .avatar.thumb-sm i {
   margin: 1px;
 }
 
 .avatar.thumb-xs i {
   margin: 0;
 }
 
 .w-1x {
   width: 1em;
 }
 
 .w-2x {
   width: 2em;
 }
 
 .w-3x {
   width: 3em;
 }
 
 .w-xxs {
   width: 60px;
 }
 
 .w-xs {
   width: 90px;
 }
 
 .w-sm {
   width: 150px;
 }
 
 .w {
   width: 200px;
 }
 
 .w-md {
   width: 240px;
 }
 
 .w-lg {
   width: 280px;
 }
 
 .w-xl {
   width: 320px;
 }
 
 .w-xxl {
   width: 360px;
 }
 
 .w-full {
   width: 100%;
 }
 
 .w-auto {
   width: auto;
 }
 
 .h-auto {
   height: auto;
 }
 
 .h-full {
   height: 100%;
 }
 
 .thumb-xl {
   display: inline-block;
   width: 128px;
 }
 
 .thumb-lg {
   display: inline-block;
   width: 96px;
 }
 
 .thumb-md {
   display: inline-block;
   width: 64px;
 }
 
 .thumb {
   display: inline-block;
   width: 50px;
 }
 
 .thumb-sm {
   display: inline-block;
   width: 40px;
 }
 
 .thumb-xs {
   display: inline-block;
   width: 34px;
 }
 
 .thumb-xxs {
   display: inline-block;
   width: 30px;
 }
 
 .thumb-wrapper {
   padding: 2px;
   border: 1px solid #dee5e7;
 }
 
 .thumb img,
 .thumb-xs img,
 .thumb-sm img,
 .thumb-md img,
 .thumb-lg img,
 .thumb-btn img {
   height: auto;
   max-width: 100%;
   vertical-align: middle;
 }
 
 .img-full {
   width: 100%;
 }
 
 .img-full img {
   width: 100%;
 }
 
 .scrollable {
   overflow-x: hidden;
   overflow-y: auto;
   -webkit-overflow-scrolling: touch;
 }
 
 .scrollable.hover,
 .scrollable.hover > .cell-inner {
   overflow-y: hidden !important;
 }
 
 .scrollable.hover:hover,
 .scrollable.hover:focus,
 .scrollable.hover:active {
   overflow: visible;
   overflow-y: auto;
 }
 
 .scrollable.hover:hover > .cell-inner,
 .scrollable.hover:focus > .cell-inner,
 .scrollable.hover:active > .cell-inner {
   overflow-y: auto !important;
 }
 
 .smart .scrollable,
 .smart .scrollable > .cell-inner {
   overflow-y: auto !important;
 }
 
 .scroll-x,
 .scroll-y {
   overflow: hidden;
   -webkit-overflow-scrolling: touch;
 }
 
 .scroll-y {
   overflow-y: auto;
 }
 
 .scroll-x {
   overflow-x: auto;
 }
 
 .hover-action {
   display: none;
 }
 
 .hover-rotate {
   -webkit-transition: all 0.2s ease-in-out 0.1s;
           transition: all 0.2s ease-in-out 0.1s;
 }
 
 .hover-anchor:hover > .hover-action,
 .hover-anchor:focus > .hover-action,
 .hover-anchor:active > .hover-action {
   display: inherit;
 }
 
 .hover-anchor:hover > .hover-rotate,
 .hover-anchor:focus > .hover-rotate,
 .hover-anchor:active > .hover-rotate {
   -webkit-transform: rotate(90deg);
       -ms-transform: rotate(90deg);
           transform: rotate(90deg);
 }
 
 .backdrop {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1050;
 }
 
 .backdrop.fade {
   opacity: 0;
   filter: alpha(opacity=0);
 }
 
 .backdrop.in {
   opacity: 0.8;
   filter: alpha(opacity=80);
 }
 
 /*desktop*/
 
 @media screen and (min-width: 992px) {
   .col-lg-2-4 {
     float: left;
     width: 20.000%;
   }
 }
 
 @media (min-width: 768px) and (max-width: 991px) {
   .hidden-sm.show {
     display: inherit !important;
   }
   .no-m-sm {
     margin: 0 !important;
   }
 }
 
 /*phone*/
 
 @media (max-width: 767px) {
   .w-auto-xs {
     width: auto;
   }
   .shift {
     display: none !important;
   }
   .shift.in {
     display: block !important;
   }
   .row-2 [class*="col"] {
     float: left;
     width: 50%;
   }
   .row-2 .col-0 {
     clear: none;
   }
   .row-2 li:nth-child(odd) {
     margin-left: 0;
     clear: left;
   }
   .text-center-xs {
     text-align: center;
   }
   .text-left-xs {
     text-align: left;
   }
   .text-right-xs {
     text-align: right;
   }
   .no-border-xs {
     border-width: 0;
   }
   .pull-none-xs {
     float: none !important;
   }
   .pull-right-xs {
     float: right !important;
   }
   .pull-left-xs {
     float: left !important;
   }
   .hidden-xs.show {
     display: inherit !important;
   }
   .wrapper-lg,
   .wrapper-md {
     padding: 15px;
   }
   .padder-lg,
   .padder-md {
     padding-right: 15px;
     padding-left: 15px;
   }
   .no-m-xs {
     margin: 0 !important;
   }
 }
 
 .butterbar {
   position: relative;
   height: 3px;
   margin-bottom: -3px;
 }
 
 .butterbar .bar {
   position: absolute;
   width: 100%;
   height: 0;
   text-indent: -9999px;
   background-color: #23b7e5;
 }
 
 .butterbar .bar:before {
   position: absolute;
   right: 50%;
   left: 50%;
   height: 3px;
   background-color: inherit;
   content: "";
 }
 
 .butterbar.active {
   -webkit-animation: changebar 2.25s infinite 0.75s;
      -moz-animation: changebar 2.25s infinite 0.75s;
           animation: changebar 2.25s infinite 0.75s;
 }
 
 .butterbar.active .bar {
   -webkit-animation: changebar 2.25s infinite;
      -moz-animation: changebar 2.25s infinite;
           animation: changebar 2.25s infinite;
 }
 
 .butterbar.active .bar:before {
   -webkit-animation: movingbar 0.75s infinite;
      -moz-animation: movingbar 0.75s infinite;
           animation: movingbar 0.75s infinite;
 }
 
 /* Moving bar */
 
 @-webkit-keyframes movingbar {
   0% {
     right: 50%;
     left: 50%;
   }
   99.9% {
     right: 0;
     left: 0;
   }
   100% {
     right: 50%;
     left: 50%;
   }
 }
 
 @-moz-keyframes movingbar {
   0% {
     right: 50%;
     left: 50%;
   }
   99.9% {
     right: 0;
     left: 0;
   }
   100% {
     right: 50%;
     left: 50%;
   }
 }
 
 @keyframes movingbar {
   0% {
     right: 50%;
     left: 50%;
   }
   99.9% {
     right: 0;
     left: 0;
   }
   100% {
     right: 50%;
     left: 50%;
   }
 }
 
 /* change bar */
 
 @-webkit-keyframes changebar {
   0% {
     background-color: #23b7e5;
   }
   33.3% {
     background-color: #23b7e5;
   }
   33.33% {
     background-color: #fad733;
   }
   66.6% {
     background-color: #fad733;
   }
   66.66% {
     background-color: #337ab7;
   }
   99.9% {
     background-color: #337ab7;
   }
 }
 
 @-moz-keyframes changebar {
   0% {
     background-color: #23b7e5;
   }
   33.3% {
     background-color: #23b7e5;
   }
   33.33% {
     background-color: #fad733;
   }
   66.6% {
     background-color: #fad733;
   }
   66.66% {
     background-color: #337ab7;
   }
   99.9% {
     background-color: #337ab7;
   }
 }
 
 @keyframes changebar {
   0% {
     background-color: #23b7e5;
   }
   33.3% {
     background-color: #23b7e5;
   }
   33.33% {
     background-color: #fad733;
   }
   66.6% {
     background-color: #fad733;
   }
   66.66% {
     background-color: #337ab7;
   }
   99.9% {
     background-color: #337ab7;
   }
 }

 
 /**************** Bootstrap DATA-Table-CSS *****************/

 div.dataTables_length label {
   font-weight: normal;
   text-align: left;
   white-space: nowrap;
 }
 
 div.dataTables_length select {
   width: 75px;
   display: inline-block;
 }
 
 div.dataTables_filter {
   text-align: right;
 }
 
 div.dataTables_filter label {
   font-weight: normal;
   white-space: nowrap;
   text-align: left;
 }
 
 div.dataTables_filter input {
   margin-left: 0.5em;
   display: inline-block;
 }
 
 div.dataTables_info {
   padding-top: 8px;
   white-space: nowrap;
 }
 
 
 @media screen and (max-width: 767px) {
   div.dataTables_length,
   div.dataTables_filter,
   div.dataTables_info{
     text-align: center;
   }
 }
 
 
 table.dataTable td,
 table.dataTable th {
   -webkit-box-sizing: content-box;
   -moz-box-sizing: content-box;
   box-sizing: content-box;
 }
 
 
 table.dataTable {
   clear: both;
   margin-top: 6px !important;
   margin-bottom: 6px !important;
   max-width: none !important;
 }
 
 table.dataTable thead .sorting,
 table.dataTable thead .sorting_asc,
 table.dataTable thead .sorting_desc,
 table.dataTable thead .sorting_asc_disabled,
 table.dataTable thead .sorting_desc_disabled {
   cursor: pointer;
 }
 
 table.dataTable thead .sorting { background: url('assets/img/sort_both.png') no-repeat center right; }
 table.dataTable thead .sorting_asc { background: url('assets/img/sort_asc.png') no-repeat center right; }
 table.dataTable thead .sorting_desc { background: url('assets/img/sort_desc.png') no-repeat center right; }

 
 table.dataTable thead > tr > th {
   padding-left: 18px;
   padding-right: 18px;
 }
 
 table.dataTable th:active {
   outline: none;
 }
 
 /* Scrolling */
 div.dataTables_scrollHead table {
   margin-bottom: 0 !important;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
 }
 
 div.dataTables_scrollHead table thead tr:last-child th:first-child,
 div.dataTables_scrollHead table thead tr:last-child td:first-child {
   border-bottom-left-radius: 0 !important;
   border-bottom-right-radius: 0 !important;
 }
 
 div.dataTables_scrollBody table {
   border-top: none;
   margin-top: 0 !important;
   margin-bottom: 0 !important;
 }
 
 div.dataTables_scrollBody tbody tr:first-child th,
 div.dataTables_scrollBody tbody tr:first-child td {
   border-top: none;
 }
 
 div.dataTables_scrollFoot table {
   margin-top: 0 !important;
   border-top: none;
 }
 
 /* Frustratingly the border-collapse:collapse used by Bootstrap makes the column
    width calculations when using scrolling impossible to align columns. We have
    to use separate
  */
 table.table-bordered.dataTable {
   border-collapse: separate !important;
 }
 table.table-bordered thead th,
 table.table-bordered thead td {
   border-left-width: 0;
   border-top-width: 0;
 }
 table.table-bordered tbody th,
 table.table-bordered tbody td {
   border-left-width: 0;
   border-bottom-width: 0;
 }
 table.table-bordered th:last-child,
 table.table-bordered td:last-child {
   border-right-width: 0;
 }
 div.dataTables_scrollHead table.table-bordered {
   border-bottom-width: 0;
 }
 
 
 
 
 /*
  * TableTools styles
  */
 .table.dataTable tbody tr.active td,
 .table.dataTable tbody tr.active th {
   background-color: #08C;
   color: white;
 }
 
 .table.dataTable tbody tr.active:hover td,
 .table.dataTable tbody tr.active:hover th {
   background-color: #0075b0 !important;
 }
 
 .table.dataTable tbody tr.active th > a,
 .table.dataTable tbody tr.active td > a {
   color: white;
 }
 
 .table-striped.dataTable tbody tr.active:nth-child(odd) td,
 .table-striped.dataTable tbody tr.active:nth-child(odd) th {
   background-color: #017ebc;
 }
 
 table.DTTT_selectable tbody tr {
   cursor: pointer;
 }
 
 div.DTTT .btn:hover {
   text-decoration: none !important;
 }
 
 ul.DTTT_dropdown.dropdown-menu {
   z-index: 2003;
 }
 
 ul.DTTT_dropdown.dropdown-menu a {
   color: #333 !important; /* needed only when demo_page.css is included */
 }
 
 ul.DTTT_dropdown.dropdown-menu li {
   position: relative;
 }
 
 ul.DTTT_dropdown.dropdown-menu li:hover a {
   background-color: #0088cc;
   color: white !important;
 }
 
 div.DTTT_collection_background {
   z-index: 2002;	
 }
 
 /* TableTools information display */
 div.DTTT_print_info {
   position: fixed;
   top: 50%;
   left: 50%;
   width: 400px;
   height: 150px;
   margin-left: -200px;
   margin-top: -75px;
   text-align: center;
   color: #333;
   padding: 10px 30px;
   opacity: 0.95;
 
   background-color: white;
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 6px;
   
   -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
           box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
 }
 
 div.DTTT_print_info h6 {
   font-weight: normal;
   font-size: 28px;
   line-height: 28px;
   margin: 1em;
 }
 
 div.DTTT_print_info p {
   font-size: 14px;
   line-height: 20px;
 }
 
 div.dataTables_processing {
     position: absolute;
     top: 50%;
     left: 50%;
     width: 100%;
     height: 60px;
     margin-left: -50%;
     margin-top: -25px;
     padding-top: 20px;
     padding-bottom: 20px;
     text-align: center;
     font-size: 1.2em;
     background-color: white;
     background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
     background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
     background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
     background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
     background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
     background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
 }
 
 
 
 /*
  * FixedColumns styles
  */
 div.DTFC_LeftHeadWrapper table,
 div.DTFC_LeftFootWrapper table,
 div.DTFC_RightHeadWrapper table,
 div.DTFC_RightFootWrapper table,
 table.DTFC_Cloned tr.even {
     background-color: white;
     margin-bottom: 0;
 }
  
 div.DTFC_RightHeadWrapper table ,
 div.DTFC_LeftHeadWrapper table {
   border-bottom: none !important;
     margin-bottom: 0 !important;
     border-top-right-radius: 0 !important;
     border-bottom-left-radius: 0 !important;
     border-bottom-right-radius: 0 !important;
 }
  
 div.DTFC_RightHeadWrapper table thead tr:last-child th:first-child,
 div.DTFC_RightHeadWrapper table thead tr:last-child td:first-child,
 div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
 div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
     border-bottom-left-radius: 0 !important;
     border-bottom-right-radius: 0 !important;
 }
  
 div.DTFC_RightBodyWrapper table,
 div.DTFC_LeftBodyWrapper table {
     border-top: none;
     margin: 0 !important;
 }
  
 div.DTFC_RightBodyWrapper tbody tr:first-child th,
 div.DTFC_RightBodyWrapper tbody tr:first-child td,
 div.DTFC_LeftBodyWrapper tbody tr:first-child th,
 div.DTFC_LeftBodyWrapper tbody tr:first-child td {
     border-top: none;
 }
  
 div.DTFC_RightFootWrapper table,
 div.DTFC_LeftFootWrapper table {
     border-top: none;
     margin-top: 0 !important;
 }
 
 
 /*
  * FixedHeader styles
  */
 div.FixedHeader_Cloned table {
   margin: 0 !important
 }
 
 
.background-white{
 background-color: white !important;
}

 /**************** ngx-breadcrumbs customized *****************/
ol.breadcrumb{
 padding: 14px 16px;
 margin-bottom: 0;
 background-color: #f6f8f8;
 font-weight: 300;
 font-size: 20px;
 border-bottom: 1px solid #dee5e7;
 color: #58666e;
}
ol.breadcrumb > .active {
 color: #6a6a6a;
}
ol.breadcrumb li:first-child{
 padding-left: 1%;
}
ol.breadcrumb > li + li::before {
 padding: 0 1px 0 6px;
 color: #ccc;
 font-family: 'Glyphicons Halflings';
 content: "\e080";
 font-size: 16px;
}
ol.breadcrumb li a{
 color: #1c6eb4; 
}
ol.breadcrumb li a:hover{
 text-decoration: underline; 
}
@media (max-width: 992px) {
 ol.breadcrumb{
   margin-top:13px;
   padding: 12px 16px;
 }
 ol.breadcrumb li{
   font-size: 18px;
 }
}


 /**************** ngx-pagination customized *****************/
.ngx-pagination {
 display: inline-block;
 padding-left: 0;
 margin: 16px 25px;
 border-radius: 4px;
}
@media (max-width: 768px) {
 .ngx-pagination {
   margin: 16px 0;
 }
}
.ngx-pagination li {
 display: inline-block;
 padding: 0 !important;
}
.ngx-pagination > li > a {
 border-color: #dee5e7;
}
.ngx-pagination > li > div > a,
.ngx-pagination > li > div > span,
.ngx-pagination > li > a,
.ngx-pagination > li > span {
 position: relative;
 float: left;
 padding: 6px 12px;
 margin-left: -1px;
 line-height: 1.42857143;
 color: #337ab7;
 text-decoration: none;
 background-color: #fff;
 border: 1px solid #ddd;
}
.ngx-pagination > .current > div > span,
.ngx-pagination > .current > span {
 z-index: 3;
color: #fff;
cursor: default;
background-color: #337ab7;
border-color: #337ab7;
}
.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before ,
.ngx-pagination .pagination-next a::after, 
.ngx-pagination .pagination-next.disabled::after {
 display: none !important;
}
.ngx-pagination .pagination-previous,
.ngx-pagination .pagination-next {
 margin-right: -4px;
 margin-left: -4px;
}
.ngx-pagination .disabled > span{
 color: #666;
 opacity: 0.7;
}
.ngx-pagination > li > a:hover, 
.ngx-pagination > li > a:focus {
 background-color: #edf1f2;
 border-color: #dee5e7;
}
.ngx-pagination > li:first-child > a,
.ngx-pagination > li:first-child > span {
 border-top-left-radius: 4px;
 border-bottom-left-radius: 4px;
}
.ngx-pagination > li:last-child > a,
.ngx-pagination > li:last-child > span {
 border-top-right-radius: 4px;
 border-bottom-right-radius: 4px;
}


 /**************** Table-layout customized *****************/
 .panel{
   font-size: 16px;   
 }
 .panel-heading{
   color: #fcfcfc !important;
   background-color: #3a3f51 !important;
   font-size: 17px;
 }
 .dataTables_wrapper .row:first-child{
   margin-bottom: 16px;
 }
 .dataTables_filter > label > input{
   width: 185px !important;
   margin-left: 6px;
 }
 .dataTables_filter label{
   font-size: 16px;
 }
 .dataTables_info{
   margin-top:25px;
 }


 /**************** Pop-up Modal customized *****************/
 .modal-dialog{
   width: 750px;
 }
 .modal-body{
   display: grid;
 }


 /**************** ng2-select multiple customized *****************/
.ui-select-multiple input.ui-select-search {
 padding: 6px 10px !important;
}
.ui-select-multiple .ui-select-match-item {
 max-width: 100%;
 overflow: hidden;
 font-size: 13px;
 white-space: normal;
 text-align: left;
}
.is-invalid .ui-select-multiple,
.is-invalid .ui-select-toggle{
 border-color: #c66361;
}

.ui-select-choices-row > a{
 white-space: normal !important;
}
.ui-select-toggle.form-control{
 height:auto;
 white-space: normal;
}

.indicator-icon { background: url('assets/img/fingerprint.png') no-repeat center;background-size: 43px; }


 /**************** loader Icon *****************/
@keyframes blink {90% { color: transparent }}
.loader__dot { animation: 1.5s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 400ms }
.loader__dot:nth-child(3) { animation-delay: 800ms }
.loader__dot{
 font-size:80px;
 
}
.loader{
   font-size: 24px;
text-align: center;
opacity: 0.85;
padding-bottom: 6px;
margin-top: -10px;
}


 /**************** refresh link *****************/
.refresh-list{
 float: right;
 font-size: 15px;
 margin-right: 12px;
 color: #ddd;
}
.refresh-list > i{
 margin-right: 8px;
 font-size: 18px;
}
.refresh-list:hover{
 color: #fff;
}


  /**************** Tree view Nestable *****************/


.dd {
 position: relative;
 display: block;
 margin: 0; padding: 0;
 max-width: 600px;
 list-style: none;
 font-size: 13px;
 line-height: 20px;
}

.dd-list { display: block; position: relative; margin: 0; padding: 0; list-style: none; }
.dd-list .dd-list { padding-left: 30px; }
.dd-collapsed .dd-list { display: none; }

.dd-item,
.dd-empty,
.dd-placeholder {
 display: block;
 position: relative;
 margin: 0;
 padding: 0;
 min-height: 20px;
 font-size: 13px;
 line-height: 20px;
}

.dd-handle {
 display: block; 
 margin: 5px 0; 
 padding: 10px 10px; 
 text-decoration: none; 
 border: 1px solid #ebebeb;
 background: #fff;
 -webkit-border-radius: 3px;
         border-radius: 3px;
}
.dd-handle:hover {background: #fff; }

.dd-item > button { display: block; position: relative; cursor: pointer; float: left; width: 25px; height: 30px; margin: 5px 0; padding: 0; text-indent: 100%; white-space: nowrap; overflow: hidden; border: 0; background: transparent; font-size: 20px; line-height: 1; text-align: center; font-weight: bold; }
.dd-item > button:before { content: '+'; display: block; position: absolute; width: 100%; text-align: center; text-indent: 0; }
.dd-item > button[data-action="collapse"]:before { content: '-'; }

.dd-placeholder,
.dd-empty { margin: 5px 0; padding: 0; min-height: 30px; background: #f2fbff; border: 1px dashed #b6bcbf; box-sizing: border-box; -moz-box-sizing: border-box; }
.dd-empty { border: 1px dashed #bbb; min-height: 100px; background-color: #e5e5e5;
 background-size: 60px 60px;
 background-position: 0 0, 30px 30px;
}

.dd-dragel { position: absolute; pointer-events: none; z-index: 9999; }
.dd-dragel > .dd-item .dd-handle { margin-top: 0; }
.dd-dragel .dd-handle {
 -webkit-box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
         box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
}

/**
* Nestable Extras
*/

.nestable-lists { display: block; clear: both; padding: 30px 0; width: 100%; border: 0; border-top: 2px solid #ddd; border-bottom: 2px solid #ddd; }

@media only screen and (min-width: 700px) { 

 .dd + .dd { margin-left: 2%; }

}

.dd-hover > .dd-handle { background: #2ea8e5 !important; }

/**
* Nestable Draggable Handles
*/

.dd3-content { display: block;margin: 5px 0;
 padding: 10px 10px 10px 50px; 
 text-decoration: none;
 border: 1px solid #ebebeb;
 background: #fff;
 -webkit-border-radius: 3px;
         border-radius: 3px;
}
.dd-dragel > .dd3-item > .dd3-content { margin: 0; }

.dd3-item > button { margin-left: 40px; }

.rivir-mat-search-label {
 margin-top: 10px;
 font-size: 15px;
 text-align: right;
 padding-right: 0;
}

.rivir-mat-top-header {
 background-color: #3a3f51 !important; 
 color: #fcfcfc;
}

.rivir-mat-table-row-odd {
   background-color : #fafbfc;
}

/* RID-3593 Fix SurveyJS Yes/No Control */
.sd-boolean.sd-boolean--checked .sd-boolean__label--true {
  color: var(--sjs-primary-backcolor, var(--primary, #19b394))!important;
  font-weight: bold;
  background-color: var(--sjs-questionpanel-backcolor, var(--sjs-question-background, var(--sjs-general-backcolor, var(--background, #fff))));
  box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, .15));
  border-radius: calc(12.5*(var(--sjs-base-unit, var(--base-unit, 8px))));
  transition-property: transform,left;
}